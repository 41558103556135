<template>
  <div class="m-2">
    <b-container>
      <b-row class="mt-1">
        <b-col xl="2" lg="3" md="4" sm="12" cols="12">
          <b-button
            class="block"
            @click="Form(0)"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Staff Performance</span>
          </b-button>
        </b-col>

        <b-col
          class="mt-lg-0 mt-md-0 mt-sm-1 mt-1"
          xl="8"
          lg="6"
          md="4"
          sm="12"
          cols="12"
        >
          <b-form-group class="ml-xl-2 ml-lg-2 ml-md-0 ml-sm-0 ml-0">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input v-model="searchQuery" placeholder="Search...">
              </b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row align-h="center" v-if="dataLoading">
        <b-spinner
          class="m-5"
          style="width: 4rem; height: 4rem"
          type="grow"
          variant="primary"
          label="Spinning"
        ></b-spinner>
      </b-row>
      <b-row
        align-h="center"
        v-else-if="filters.length == 0"
        style="height: 20vh"
      >
        <h1 class="mt-1 mb-1" style="align-self: center">No records found</h1>
      </b-row>
      <b-row class="mt-2" align-h="start" v-else>
        <b-col
          v-for="c in filters"
          :key="c"
          class="ml-lg-0 ml-md-0 ml-sm-0 ml-2"
          xl="3"
          lg="4"
          md="4"
          sm="12"
          cols="12"
        >
          <a>
            <b-card
              no-body
              style="max-width: 25rem"
              class="initial"
              v-bind:class="{ clickcard: active1 }"
              @click="Form(c.id)"
            >
              <b-card
                align-v="center"
                bg-variant="primary"
                text-variant="white"
                class="text-center mb-0"
                style="height: 150px; border-radius: 5px"
              >
                <b-card-title
                  ><h1
                    class="my-lg-3 my-md-* my-sm-3 my-3"
                    style="color: white"
                  >
                    {{ c.title }}
                  </h1>
                </b-card-title>
                <div style="position: absolute; top: 0px; right: 0px">
                  <b-button
                    @click="Form(c.id)"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-light"
                    class="mr-1 mt-1 btn-icon rounded-circle"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>

                  <b-button
                    @click="Delete(c.id)"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="danger"
                    class="mr-1 mt-1 btn-icon rounded-circle"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </div>
              </b-card>
            </b-card>
          </a>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BContainer,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BInputGroupPrepend,
  BFormInput,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from "@core/utils/filter";
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,

    BCard,
    BRow,
    BCol,
    //BAvatar,
    BFormInput,
    BButton,
    BFormGroup,
    //BFormGroup,
    // BFormCheckbox,
    //BForm,
    BContainer,
    //BCardTitle,
    BCardText,
    BInputGroupPrepend,
    BInputGroup,
    //BMediaAside,
    //BImg ,
    // BMedia,
    //BAvatar,
    //BLink,
    BSpinner,
    //BBadge,
    //BDropdown,
    //BDropdownItem,
    //BPagination,
    //vSelect,
    //flatPickr,
    //'gallery': VueGallery
  },
  props: {
    /* modalFilter: {
      type: [String, null],
      default: null,
      },
      modalFilterOptions: {
      type: Array,
      required: true,
      }*/
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  async created() {
    this.LoadData();
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),
    PageChange() {
      console.log(this.currentPage);
      return this.LoadData();
    },
    filters: function () {
      return this.items.filter((pro) => {
        return pro.title.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      totalRows: 0,
      dataLoading: false,
      errors: {
        status: false,
      },
      statusOptions: ["In stock", "Out stock"],
      filterData: "",
      date: {
        range: "",
      },
      index: null,
      cover: "",
      docs: [],
      partners: [],
      fields: [
        "#",
        { label: "Flavour", key: "Name" },
        { label: "Status", key: "Status" },
        "actions",
      ],
      items: [],
      selected: null,
      filterOptions: [
        { text: "Most sold product", value: "mostSold" },
        { text: "Least sold product", value: "leastSold" },
        { text: "Most popular product", value: "mostPopular" },
        { text: "Least popular product", value: "leastPopular" },
        { text: "Most profitable product", value: "mostProfitable" },
      ],
      rangeDate: null,
      myObj: {
        flavourId: 0,
        vendorId: this.$store.state.userData.userID,
        Name: "",
        Status: "",
      },
      detailObj: {},
    };
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),
    Form(id) {
      // console.log(id);
      this.$router.push({
        name: "manage-performance",
        params: {
          id: id,
        },
      });
      // this.$router.push({
      //   path: "/manage-performance/" + id,
      // });
    },
    checkTitle() {
      var elem = this.$refs["title"];
      return (elem.state = this.myObj.Name.length > 2 ? true : false);
    },
    Edit(row) {
      console.log(row);
      // this.$router.push({ name: 'apps-schoolinfo', params: {
      //     row: row
      //      } });

      this.$router.push({
        name: "manage-addbranch",
        params: {
          row: { row },
        },
      });
      // self.$router.push({
      //     name: 'apps-schoolinfo',
      //     params: {
      //         otherProp: {
      //             "row": row
      //         }
      //     }
      // })
      console.log("Hello", row);
      // this.$router.push('/apps/schoolinfo');
    },
    checkStatus() {
      if (this.myObj.Status == "") {
        return (this.errors.status = true);
      } else {
        return (this.errors.status = false);
      }
    },
    OpenAddModal() {
      this.$bvModal.show("modal-login");
      this.myObj.flavourId = 0;
      this.myObj.vendorId = this.$store.state.userData.userID;
      this.myObj.Name = "";
      this.myObj.Status = "";
    },
    OpenEditModal(row) {
      this.$bvModal.show("modal-login");
      this.myObj.flavourId = row.flavourId;
      this.myObj.vendorId = row.vendorId;
      this.myObj.Name = row.Name;
      this.myObj.Status = row.Status;
    },
    async Delete(id) {
      event.stopPropagation();
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "StaffPerformanceCategories/" +
            id +
            "?db=" +
            this.$store.state.userData.db +
            "&cId=" +
            this.$store.state.userData.cId,
          message: "Staff performance deleted successfully.",
          context: this,
          body: null,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData();
      }
      // return console.log(id);
    },
    AddFlavour() {
      this.checkStatus();
      if (this.checkTitle() == false || this.checkStatus() == true) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-bottom-center",
        });
      } else {
        console.log(this.myObj);
        if (this.myObj.flavourId == 0) {
          var axios = require("axios");
          // this.$store.state.userData.userID
          var config = {
            method: "post",
            url: "https://okaaik.fastech.pk/api/flavours/postflavour/",
            headers: {
              Authorization:
                "bearer T6O1i268ccP1C_94E49EDA_4sATCbEdIPthcLv1QyEVpyyvnTBzJ-6c79dAr1M9IeDV5gcBQhluDPPxmKok0XNUf5S5nM4jPvUGlyoeglhPvGKjJNaLSe6Kz2kLwUn0VML_mLWcHBtz5cBWqpipD_TiMT1_bEFG-p9i4gswBn0GIrKtX4QMSSa9O82E5qrKwOcEV2hR4Y-FJADFticsWbP2wrQQfK6EPbc1Y8GTwBchu7-HrMCHyJNO06BpWoUB2lUkGktZuqsmEeRSgrDc8fAuQjbqpwUmAD_KYCVwK7avahTN6MVGIVCgu5u54cG-4",
            },
            data: this.myObj,
          };

          axios(config)
            .then((response) => {
              if (response.data.status === "success") {
                console.log(response.data);
                this.$bvModal.hide("modal-login");
                this.LoadData();

                this.$bvToast.toast("Flavour added.", {
                  title: "Success!",
                  variant: "success",
                  toaster: "b-toaster-bottom-center",
                });
              }

              //  this.data  =  response.data;
              //console.log(this.$store.state.userData.userID);
              //this.items = response.data;

              // console.log(this.items);
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          //Edit
          var axios = require("axios");
          // this.$store.state.userData.userID
          var config = {
            method: "put",
            url:
              "https://okaaik.fastech.pk/api/flavours/PutFlavour/" +
              this.myObj.flavourId,
            headers: {
              Authorization:
                "bearer T6O1i268ccP1C_94E49EDA_4sATCbEdIPthcLv1QyEVpyyvnTBzJ-6c79dAr1M9IeDV5gcBQhluDPPxmKok0XNUf5S5nM4jPvUGlyoeglhPvGKjJNaLSe6Kz2kLwUn0VML_mLWcHBtz5cBWqpipD_TiMT1_bEFG-p9i4gswBn0GIrKtX4QMSSa9O82E5qrKwOcEV2hR4Y-FJADFticsWbP2wrQQfK6EPbc1Y8GTwBchu7-HrMCHyJNO06BpWoUB2lUkGktZuqsmEeRSgrDc8fAuQjbqpwUmAD_KYCVwK7avahTN6MVGIVCgu5u54cG-4",
            },
            data: this.myObj,
          };

          axios(config)
            .then((response) => {
              if (response.data.status === "success") {
                this.$bvModal.hide("modal-login");
                console.log(response.data);
                this.LoadData();

                this.$bvToast.toast("Flavour updated.", {
                  title: "Success!",
                  variant: "success",
                  toaster: "b-toaster-bottom-center",
                });
              } else {
                this.$bvToast.toast("Something went wrong.", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-bottom-center",
                });
              }

              //  this.data  =  response.data;
              //console.log(this.$store.state.userData.userID);
              //this.items = response.data;

              // console.log(this.items);
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }
    },

    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "StaffPerformanceCategories?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
      this.dataLoading = false;

      // console.log("item", this.items);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
